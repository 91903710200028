import axios from 'axios'
import Environment from '../../../../environments/environment'
import {Interceptors} from './Interceptors'

export const weScoreApi = axios.create({
  baseURL: Environment.getUrl(),
  // headers: {
  //   'Content-Type': 'application/json',
  //   Accept: 'application/json',
  // },
})

weScoreApi.interceptors.response.use(
  (response) => Interceptors.responseInterceptorSuccess(response),
  (err) => Interceptors.responseInterceptorError(err)
)
