import {IMenuModel} from './../../@types/models/MenuModel'

export const menuItems: IMenuModel[] = [
  {
    modulo: 'Cadastro',
  },
  {
    modulo: 'Premiados',
  },
  {
    modulo: 'Campanha',
  },
  {
    modulo: 'Pedidos',
  },
  {
    modulo: 'Financeiro',
  },
  {
    modulo: 'Loja/Produto',
  },
  {
    modulo: 'Relatórios',
  },
  {
    modulo: 'Comunicados',
  },
]
