import BreadCrumbService from './breadCrumbService';
import BlockUIService from './blockUIService';
import AuthService from './authService';

interface OnInit {
  init: () => void;
}

export default class AbstractController implements OnInit {
  public breadCrumbService: BreadCrumbService;

  public blockUIService: BlockUIService;

  private _authService: AuthService;

  constructor() {
    this.breadCrumbService = new BreadCrumbService();
    this.blockUIService = new BlockUIService();
    this._authService = new AuthService();
  }

  init(): void { }


  enableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) {
      splashScreen.style.setProperty('display', 'flex')
    }
  }
  
  disableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) {
      splashScreen.style.setProperty('display', 'none')
    }
  }

  getUser() {
    return this._authService.getUser();
  }

  extractSearchParams(url: any) {
    return new Proxy(new URLSearchParams(url), {
      get: (searchParams: any, prop: any) => searchParams.get(prop),
    });
  }

  // verificaPermissaoAcessoPagina(permissao: string) {
  //   this._authService.verificaPermissaoAcessoPagina(permissao);
  // }
}
