import {FC} from 'react'

interface Props {
  className: string
  name: string
  placeholder: string | any
  value?: string
  disabled?: boolean | any
  options: any[]
  onBlur: (value: string | number | any) => void
  onChange: (value: string | number | any) => void
}

const Dropdown: FC<Props> = ({...rest}) => {
  return (
    <>
      <select
        className={rest.className}
        id={rest.name}
        name={rest.name}
        disabled={rest.disabled}
        onBlur={rest.onBlur}
        onChange={rest.onChange}
        value={rest.value}
      >
        <option value=''>{rest.placeholder}</option>
        {rest.options?.map((option, index) => (
          <option value={option.value} key={index}>
            {option.text}
          </option>
        ))}
      </select>
    </>
  )
}

export {Dropdown}
