export default class Environment {
  private static env = process.env.REACT_APP_ENV

  private static url = process.env.REACT_APP_API_URL

  private static urlWeb = process.env.REACT_APP_WEB_URL

  public static getEnv() {
    return this.env
  }

  public static getUrl() {
    return this.url
  }

  public static getUrlWeb() {
    return this.urlWeb
  }
}
