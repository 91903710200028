import { TResponse } from '../vendors/@types/http'
import { IPasswordModel } from '../vendors/@types/models/PasswordModel'
import AbstractService from '../vendors/services/abstractService'
import { weScoreApi } from '../vendors/shared/apis/api'

export default class PasswordService extends AbstractService {

    constructor() {
        super(weScoreApi, '/v1/password');
    }

    public recuperarSenha(dados: IPasswordModel): Promise<TResponse<any>> {

        const output = JSON.parse(JSON.stringify(dados), (_key, value) =>
            value === null || value === '' ? undefined : value,
        );
        const queryString = new URLSearchParams(output).toString();
        return this.api
            .get(`${this.rota}/recuperar-senha?${queryString}`)
            .then((resp) => {
                if (resp.data.statusCode === 200) {
                    return resp.data;
                }
            })
            .catch((resp) => {
                return { statusCode: 404, data: [], metaData: resp.data.metaData }
            });
    }

    public resetarSenha(dados: any): Promise<TResponse<any>> {
        
        return this.api
            .post(`${this.rota}/resetar-senha/`,dados)
            .then((resp) => {
                if (resp.data.statusCode === 200) {
                    return resp.data;
                } else if (resp.data.statusCode === 204) {
                    return { statusCode: resp.data.statusCode, data: [], metaData: resp.data.metaData, message: resp.data.msg }
                }
            })
            .catch((resp) => {
                if (resp.data.statusCode === 400) {
                    return { statusCode: resp.data.statusCode, data: resp.data, message: "Erro!" }
                } else {
                    return resp.data
                }
            });
    }
}
