import { TResponse } from '../vendors/@types/http'
import AbstractService from '../vendors/services/abstractService'
import { weScoreApi } from '../vendors/shared/apis/api'

export default class PerfilService extends AbstractService {

    constructor() {
        super(weScoreApi, '/v1/perfis');
    }

    public obterPerfil(): Promise<TResponse<any>> {
        return this.api
            .get(`${this.rota}/d-lista-perfis?metaData.pageNumber=1&metaData.pageSize=1000`)
            .then((resp) => {
                if (resp.data.statusCode === 200) {
                    return resp.data;
                } else if (resp.data.statusCode === 204) {
                    return { statusCode: resp.data.statusCode, data: [], metaData: resp.data.metaData, message: resp.data.msg }
                }
            })
            .catch((resp) => {
                return { statusCode: 404, data: [], metaData: resp.data.metaData }
            });
    }

    public obterPermissao(): Promise<TResponse<any>> {
        return this.api
            .get(`${this.rota}/d-lista-permissao`)
            .then((resp) => {
                if (resp.data.statusCode === 200) {
                    return resp.data;
                } else if (resp.data.statusCode === 204) {
                    return { statusCode: resp.data.statusCode, data: [], metaData: resp.data.metaData, message: resp.data.msg }
                }
            })
            .catch((resp) => {
                return { statusCode: 404, data: [], metaData: resp.data.metaData }
            });
    }
}
