/* eslint-disable */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {Route, Link, Routes, Outlet} from 'react-router-dom'
import {AuthLayout} from '../auth/AuthLayout'
import {ConfirmEmail} from './components/ConfirmEmail'

const ConfirmarEmailPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='/Email/ConfirmEmail' element={<ConfirmEmail />} />
      <Route index element={<ConfirmEmail />} />
    </Route>
  </Routes>
)

export {ConfirmarEmailPage}
