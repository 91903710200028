import { TResponse } from '../vendors/@types/http'
import AbstractService from '../vendors/services/abstractService'
import { weScoreApi } from '../vendors/shared/apis/api'

export default class Departamento extends AbstractService {

    constructor() {
        super(weScoreApi, '/v1/departamento');
    }

    public obterDepartamento(): Promise<TResponse<any>> {
        return this.api
            .get(`${this.rota}/d-lista-departamentos?metaData.pageNumber=1&metaData.pageSize=20`)
            .then((resp) => {
                if (resp.data.statusCode === 200) {
                    return resp.data;
                } else if (resp.data.statusCode === 204) {
                    return { statusCode: resp.data.statusCode, data: [], metaData: resp.data.metaData, message: resp.data.msg }
                }
            })
            .catch((resp) => {
                //console.clear();
                return { statusCode: 404, data: [], metaData: resp.data.metaData }
            });
    }

    public obterDepartamentoLoja(): Promise<TResponse<any>> {
        return this.api
            .get(`${this.rota}/d-lista-departamentos-loja?metaData.pageNumber=1&metaData.pageSize=20`)
            .then((resp) => {
                if (resp.data.statusCode === 200) {
                    return resp.data;
                } else if (resp.data.statusCode === 204) {
                    return { statusCode: resp.data.statusCode, data: [], metaData: resp.data.metaData, message: resp.data.msg }
                }
            })
            .catch((resp) => {
                //console.clear();
                return { statusCode: 404, data: [], metaData: resp.data.metaData }
            });
    }

    public obterDepartamentoProduto(): Promise<TResponse<any>> {
        return this.api
            .get(`${this.rota}/d-lista-departamentos-produto?metaData.pageNumber=1&metaData.pageSize=20`)
            .then((resp) => {
                if (resp.data.statusCode === 200) {
                    return resp.data;
                } else if (resp.data.statusCode === 204) {
                    return { statusCode: resp.data.statusCode, data: [], metaData: resp.data.metaData, message: resp.data.msg }
                }
            })
            .catch((resp) => {
                //console.clear();
                return { statusCode: 404, data: [], metaData: resp.data.metaData }
            });
    }

    public obterDepartamentoCliente(): Promise<TResponse<any>> {
        return this.api
            .get(`${this.rota}/d-lista-departamentos-cliente?metaData.pageNumber=1&metaData.pageSize=100`)
            .then((resp) => {
                if (resp.data.statusCode === 200) {
                    return resp.data;
                } else if (resp.data.statusCode === 204) {
                    return { statusCode: resp.data.statusCode, data: [], metaData: resp.data.metaData, message: resp.data.msg }
                }
            })
            .catch((resp) => {
                return { statusCode: 404, data: [], metaData: resp.data.metaData }
            });
    }

    public obterDepartamentoFornecedor(): Promise<TResponse<any>> {
        return this.api
            .get(`${this.rota}/d-lista-departamentos-fornecedor?metaData.pageNumber=1&metaData.pageSize=20`)
            .then((resp) => {
                if (resp.data.statusCode === 200) {
                    return resp.data;
                } else if (resp.data.statusCode === 204) {
                    return { statusCode: resp.data.statusCode, data: [], metaData: resp.data.metaData, message: resp.data.msg }
                }
            })
            .catch((resp) => {
                //console.clear();
                return { statusCode: 404, data: [], metaData: resp.data.metaData }
            });
    }

    public obterDepartamentoUsuario(): Promise<TResponse<any>> {
        return this.api
            .get(`${this.rota}/d-lista-departamentos-usuarios?metaData.pageNumber=1&metaData.pageSize=20`)
            .then((resp) => {
                if (resp.data.statusCode === 200) {
                    return resp.data;
                } else if (resp.data.statusCode === 204) {
                    return { statusCode: resp.data.statusCode, data: [], metaData: resp.data.metaData, message: resp.data.msg }
                }
            })
            .catch((resp) => {
                //console.clear();
                return { statusCode: 404, data: [], metaData: resp.data.metaData }
            });
    }

    public obterDepartamentoLojaPorId(id:string| any|null): Promise<TResponse<any>> {
        return this.api
            .get(`${this.rota}/d-lista-departamentos-loja-id?idLoja=${id}&metaData.pageNumber=1&metaData.pageSize=1000`)
            .then((resp) => {
                if (resp.data.statusCode === 200) {
                    return resp.data;
                } else if (resp.data.statusCode === 204) {
                    return { statusCode: resp.data.statusCode, data: [], metaData: resp.data.metaData, message: resp.data.msg }
                }
            })
            .catch((resp) => {
                //console.clear();
                return { statusCode: 404, data: [], metaData: resp.data.metaData }
            });
    }
}
