/* eslint-disable */
export class Mensagem {
  public static readonly FALHA_REQUISICAO = 'Falha na requisição';

  public static readonly SUCESSO = 'Sucesso';

  public static readonly ERRO = 'Erro';

  // MENSAGENS DE ALERTA
  public static readonly MSG_A001 = 'Data final maior que data inicial.';

  public static readonly MSG_A002 = 'Deseja realmente excluir';

  public static readonly MSG_A003 = 'Este CNPJ já está cadastrado';

  public static readonly MSG_A004 = '';

  public static readonly MSG_A005 = '';

  public static readonly MSG_A006 = '';

  public static readonly MSG_A007 = '';

  public static readonly MSG_A008 = '';

  public static readonly MSG_A009 = '';

  public static readonly MSG_A010 = '';

  // MENSAGENS DE SUCESSO
  public static readonly MSG_S001 = 'Cadastro concluído com sucesso.';

  public static readonly MSG_S002 = 'Exclusão com sucesso.';

  public static readonly MSG_S003 = 'Salvo com sucesso!';

  public static readonly MSG_E004 = (variavelPrincipal: string, generoDaVariavel: string) =>
    variavelPrincipal + ' inválid' + generoDaVariavel + '!';

  // public static readonly MSG_A001 = 'Al concluído com sucesso.';

  // public static readonly MSG_S015 = 'Ação realizada com sucesso.';

  // public static readonly MSG_S016 = 'Parecer gerado com sucesso.';

  // public static readonly MSG_E002 = '';
}
