import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'
import {IMenuModel} from '../../../../../app/vendors/@types/models/MenuModel'
import {menuItems} from '../../../../../app/vendors/shared/menu'
import JwtUtil from '../../../../../app/vendors/shared/util/jwt.util'
import {Utils} from '../../../../../app/vendors/shared/util/Utils'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [visivelCadastro, setVisivelCadastro]: any = useState<boolean>(false)
  const [visivelPremiados, setVisivelPremiados]: any = useState<boolean>(false)
  const [visivelCampanha, setVisivelCampanha]: any = useState<boolean>(false)
  const [visivelPedido, setVisivelPedido]: any = useState<boolean>(false)
  const [visivelFinanceiro, setVisivelFinanceiro]: any = useState<boolean>(false)
  const [visivelLojaProduto, setVisivelLojaProduto]: any = useState<boolean>(false)
  const [visivelRelatorios, setVisivelRelatorios]: any = useState<boolean>(false)
  const [visivelComunicados, setVisivelComunicados]: any = useState<boolean>(false)

  const [visivelCadastroVisualizar, setVisivelCadastroVisualizar]: any = useState<boolean>(false)
  const [visivelPremiadosVisualizar, setVisivelPremiadosVisualizar]: any = useState<boolean>(false)
  const [visivelCampanhaVisualizar, setVisivelCampanhaVisualizar]: any = useState<boolean>(false)
  const [visivelPedidoVisualizar, setVisivelPedidoVisualizar]: any = useState<boolean>(false)
  const [visivelFinanceiroVisualizar, setVisivelFinanceiroVisualizar]: any =
    useState<boolean>(false)
  const [visivelLojaProdutoVisualizar, setVisivelLojaProdutoVisualizar]: any =
    useState<boolean>(false)
  const [visivelRelatoriosVisualizar, setVisivelRelatoriosVisualizar]: any =
    useState<boolean>(false)
  const [visivelComunicadosVisualizar, setVisivelComunicadosVisualizar]: any =
    useState<boolean>(false)

  const [visivelCadastroEditar, setVisivelCadastroEditar]: any = useState<boolean>(false)
  const [visivelPremiadosEditar, setVisivelPremiadosEditar]: any = useState<boolean>(false)
  const [visivelCampanhaEditar, setVisivelCampanhaEditar]: any = useState<boolean>(false)
  const [visivelPedidoEditar, setVisivelPedidoEditar]: any = useState<boolean>(false)
  const [visivelFinanceiroEditar, setVisivelFinanceiroEditar]: any = useState<boolean>(false)
  const [visivelLojaProdutoEditar, setVisivelLojaProdutoEditar]: any = useState<boolean>(false)
  const [visivelRelatoriosEditar, setVisivelRelatoriosEditar]: any = useState<boolean>(false)
  const [visivelComunicadosEditar, setVisivelComunicadosEditar]: any = useState<boolean>(false)

  const [visivelCadastroCadastrar, setVisivelCadastroCadastrar]: any = useState<boolean>(false)
  const [visivelPremiadosCadastrar, setVisivelPremiadosCadastrar]: any = useState<boolean>(false)
  const [visivelCampanhaCadastrar, setVisivelCampanhaCadastrar]: any = useState<boolean>(false)
  const [visivelPedidoCadastrar, setVisivelPedidoCadastrar]: any = useState<boolean>(false)
  const [visivelFinanceiroCadastrar, setVisivelFinanceiroCadastrar]: any = useState<boolean>(false)
  const [visivelLojaProdutoCadastrar, setVisivelLojaProdutoCadastrar]: any =
    useState<boolean>(false)
  const [visivelRelatoriosCadastrar, setVisivelRelatoriosCadastrar]: any = useState<boolean>(false)
  const [visivelComunicadosCadastrar, setVisivelComunicadosCadastrar]: any =
    useState<boolean>(false)

  const separarAcessToken = (dadosToken: any, menu: any | IMenuModel[]) => {
    const menuArray: any = []
    menu.forEach((element: IMenuModel) => {
      Object.values(element).forEach((key) => {
        menuArray.push(key)
      })
    })

    const dados = JSON.parse(dadosToken.permissao)

    const novoArray: any = []
    dados.forEach((element: IMenuModel) => {
      Object.keys(element).forEach((key) => {
        novoArray.push(key)
      })
    })

    const difference = menuArray.filter((x: any) => !novoArray.includes(x))
    return difference
  }

  const authorizeCore = (nomeMenu: any): boolean | any => {
    const temp = [...menuItems]
    const jwt = JwtUtil.parseJwt(currentUser)

    const menuBloqueado = separarAcessToken(jwt, temp)

    const dadosPermissao = JSON.parse(jwt.permissao)

    const difference = nomeMenu.filter((item: any) => !menuBloqueado.includes(item))

    if (dadosPermissao.length === 0) {
      setVisivelCadastro(true)
      setVisivelPremiados(true)
      setVisivelCampanha(true)
      setVisivelPedido(true)
      setVisivelFinanceiro(true)
      setVisivelLojaProduto(true)
      setVisivelRelatorios(true)
      setVisivelComunicados(true)
      setVisivelCadastroVisualizar(true)
      setVisivelCadastroEditar(true)
      setVisivelCadastroCadastrar(true)
      setVisivelPremiadosVisualizar(true)
      setVisivelPremiadosCadastrar(true)
      setVisivelCampanhaVisualizar(true)
      setVisivelCampanhaEditar(true)
      setVisivelCampanhaCadastrar(true)
      setVisivelPedidoVisualizar(true)
      setVisivelPedidoEditar(true)
      setVisivelPedidoCadastrar(true)
      setVisivelFinanceiroVisualizar(true)
      setVisivelFinanceiroEditar(true)
      setVisivelLojaProdutoVisualizar(true)
      setVisivelLojaProdutoCadastrar(true)
      setVisivelLojaProdutoEditar(true)
      setVisivelRelatoriosVisualizar(true)
      setVisivelComunicadosVisualizar(true)
      setVisivelComunicadosEditar(true)
      setVisivelComunicadosCadastrar(true)
    } else {
      difference.forEach((element: any) => {
        if (element === 'Cadastro') {
          setVisivelCadastro(true)
        } else if (element === 'Premiados') {
          setVisivelPremiados(true)
        } else if (element === 'Campanha') {
          setVisivelCampanha(true)
        } else if (element === 'Pedidos') {
          setVisivelPedido(true)
        } else if (element === 'Financeiro') {
          setVisivelFinanceiro(true)
        } else if (element === 'Loja/Produto') {
          setVisivelLojaProduto(true)
        } else if (element === 'Relatórios') {
          setVisivelRelatorios(true)
        } else if (element === 'Comunicados') {
          setVisivelComunicados(true)
        }
      })

      for (const item of dadosPermissao) {
        if ('Cadastro' in item && item['Cadastro'].includes('Visualizar')) {
          setVisivelCadastroVisualizar(true)
        }

        if ('Cadastro' in item && item['Cadastro'].includes('Editar')) {
          setVisivelCadastroEditar(true)
        }

        if ('Cadastro' in item && item['Cadastro'].includes('Cadastrar')) {
          setVisivelCadastroCadastrar(true)
        }

        if ('Premiados' in item && item['Premiados'].includes('Visualizar')) {
          setVisivelPremiadosVisualizar(true)
        }

        if ('Premiados' in item && item['Premiados'].includes('Editar')) {
          setVisivelPremiadosEditar(true)
        }

        if ('Premiados' in item && item['Premiados'].includes('Cadastrar')) {
          setVisivelPremiadosCadastrar(true)
        }

        if ('Campanha' in item && item['Campanha'].includes('Visualizar')) {
          setVisivelCampanhaVisualizar(true)
        }

        if ('Campanha' in item && item['Campanha'].includes('Editar')) {
          setVisivelCampanhaEditar(true)
        }

        if ('Campanha' in item && item['Campanha'].includes('Cadastrar')) {
          setVisivelCampanhaCadastrar(true)
        }

        if ('Pedidos' in item && item['Pedidos'].includes('Visualizar')) {
          setVisivelPedidoVisualizar(true)
        }

        if ('Pedidos' in item && item['Pedidos'].includes('Editar')) {
          setVisivelPedidoEditar(true)
        }

        if ('Pedidos' in item && item['Pedidos'].includes('Cadastrar')) {
          setVisivelPedidoCadastrar(true)
        }

        if ('Financeiro' in item && item['Financeiro'].includes('Visualizar')) {
          setVisivelFinanceiroVisualizar(true)
        }

        if ('Financeiro' in item && item['Financeiro'].includes('Editar')) {
          setVisivelFinanceiroEditar(true)
        }

        if ('Loja/Produto' in item && item['Loja/Produto'].includes('Visualizar')) {
          setVisivelLojaProdutoVisualizar(true)
        }

        if ('Loja/Produto' in item && item['Loja/Produto'].includes('Cadastrar')) {
          setVisivelLojaProdutoCadastrar(true)
        }

        if ('Loja/Produto' in item && item['Loja/Produto'].includes('Editar')) {
          setVisivelLojaProdutoEditar(true)
        }

        if ('Relatórios' in item && !item['Relatórios'].includes('Visualizar')) {
          setVisivelRelatoriosVisualizar(true)
        }

        if ('Comunicados' in item && item['Comunicados'].includes('Visualizar')) {
          setVisivelComunicadosVisualizar(true)
        }

        if ('Comunicados' in item && item['Comunicados'].includes('Editar')) {
          setVisivelComunicadosEditar(true)
        }

        if ('Comunicados' in item && item['Comunicados'].includes('Cadastrar')) {
          setVisivelComunicadosCadastrar(true)
        }
      }
    }
  }

  useEffect(() => {
    setVisivelCadastro(visivelCadastro)
  }, [visivelCadastro])

  useEffect(() => {
    setVisivelPremiados(visivelPremiados)
  }, [visivelPremiados])

  useEffect(() => {
    setVisivelCampanha(visivelCampanha)
  }, [visivelCampanha])

  useEffect(() => {
    setVisivelPedido(visivelPedido)
  }, [visivelPedido])

  useEffect(() => {
    setVisivelFinanceiro(visivelFinanceiro)
  }, [visivelFinanceiro])

  useEffect(() => {
    setVisivelLojaProduto(visivelLojaProduto)
  }, [visivelLojaProduto])

  useEffect(() => {
    setVisivelRelatorios(visivelRelatorios)
  }, [visivelRelatorios])

  useEffect(() => {
    setVisivelComunicados(visivelComunicados)
  }, [visivelComunicados])

  useEffect(() => {
    setVisivelCadastroVisualizar(visivelCadastroVisualizar)
  }, [visivelCadastroVisualizar])

  useEffect(() => {
    setVisivelPremiadosVisualizar(visivelPremiadosVisualizar)
  }, [visivelPremiadosVisualizar])

  useEffect(() => {
    setVisivelCampanhaVisualizar(visivelCampanhaVisualizar)
  }, [visivelCampanhaVisualizar])

  useEffect(() => {
    setVisivelPedidoVisualizar(visivelPedidoVisualizar)
  }, [visivelPedidoVisualizar])

  useEffect(() => {
    setVisivelFinanceiroVisualizar(visivelFinanceiroVisualizar)
  }, [visivelFinanceiroVisualizar])

  useEffect(() => {
    setVisivelLojaProdutoVisualizar(visivelLojaProdutoVisualizar)
  }, [visivelLojaProdutoVisualizar])

  useEffect(() => {
    setVisivelRelatoriosVisualizar(visivelRelatoriosVisualizar)
  }, [visivelRelatoriosVisualizar])

  useEffect(() => {
    setVisivelComunicadosVisualizar(visivelComunicadosVisualizar)
  }, [visivelComunicadosVisualizar])

  useEffect(() => {
    setVisivelCadastroEditar(visivelCadastroEditar)
  }, [visivelCadastroEditar])

  useEffect(() => {
    setVisivelPremiadosEditar(visivelPremiadosEditar)
  }, [visivelPremiadosEditar])

  useEffect(() => {
    setVisivelCampanhaEditar(visivelCampanhaEditar)
  }, [visivelCampanhaEditar])

  useEffect(() => {
    setVisivelPedidoEditar(visivelPedidoEditar)
  }, [visivelPedidoEditar])

  useEffect(() => {
    setVisivelFinanceiroEditar(visivelFinanceiroEditar)
  }, [visivelFinanceiroEditar])

  useEffect(() => {
    setVisivelLojaProdutoEditar(visivelLojaProdutoEditar)
  }, [visivelLojaProdutoEditar])

  useEffect(() => {
    setVisivelRelatoriosEditar(visivelRelatoriosEditar)
  }, [visivelRelatoriosEditar])

  useEffect(() => {
    setVisivelComunicadosEditar(visivelComunicadosEditar)
  }, [visivelComunicadosEditar])
  ////
  useEffect(() => {
    setVisivelCadastroCadastrar(visivelCadastroCadastrar)
  }, [visivelCadastroCadastrar])

  useEffect(() => {
    setVisivelPremiadosCadastrar(visivelPremiadosCadastrar)
  }, [visivelPremiadosCadastrar])

  useEffect(() => {
    setVisivelCampanhaCadastrar(visivelCampanhaCadastrar)
  }, [visivelCampanhaCadastrar])

  useEffect(() => {
    setVisivelPedidoCadastrar(visivelPedidoCadastrar)
  }, [visivelPedidoCadastrar])

  useEffect(() => {
    setVisivelFinanceiroCadastrar(visivelFinanceiroCadastrar)
  }, [visivelFinanceiroCadastrar])

  useEffect(() => {
    setVisivelLojaProdutoCadastrar(visivelLojaProdutoCadastrar)
  }, [visivelLojaProdutoCadastrar])

  useEffect(() => {
    setVisivelRelatoriosCadastrar(visivelRelatoriosCadastrar)
  }, [visivelRelatoriosCadastrar])

  useEffect(() => {
    setVisivelComunicadosCadastrar(visivelComunicadosCadastrar)
  }, [visivelComunicadosCadastrar])

  useEffect(() => {
    authorizeCore(Utils.AUTH_MENU)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Menu</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/pages/cadastro'
        title='Cadastros'
        icon='/media/icons/duotune/communication/com006.svg'
        visibleMenu={visivelCadastro}
      >
        <SidebarMenuItem
          to='/pages/cadastro/cliente'
          title='Clientes'
          hasBullet={true}
          visibleMenuItem={true}
        />
        <SidebarMenuItem
          to='/pages/cadastro/premiado'
          title='Premiados'
          hasBullet={true}
          visibleMenuItem={visivelCadastroCadastrar}
        />
        <SidebarMenuItem
          to='/pages/cadastro/usuario'
          title='Usuários'
          hasBullet={true}
          visibleMenuItem={true}
        />
        <SidebarMenuItem
          to='/pages/cadastro/fornecedor'
          title='Fornecedores'
          hasBullet={true}
          visibleMenuItem={true}
        />
      </SidebarMenuItemWithSub>
      {/* Premiados */}
      <SidebarMenuItemWithSub
        to='/pages/premiado'
        title='Premiados'
        icon='/media/icons/duotune/general/gen022.svg'
        visibleMenu={visivelPremiados}
      >
        <SidebarMenuItem
          to='/pages/premiado/consultarPremiado'
          title='Consultar Premiados'
          hasBullet={true}
          visibleMenuItem={visivelPremiadosCadastrar || visivelPremiadosEditar}
        />
        <SidebarMenuItem
          to='/pages/premiado/estorno'
          title='Estornos'
          hasBullet={true}
          visibleMenuItem={visivelPremiadosVisualizar}
        />
        <SidebarMenuItem
          to='/pages/premiado/transferencia'
          title='Transferência'
          hasBullet={true}
          visibleMenuItem={visivelPremiadosVisualizar}
        />
        <SidebarMenuItem
          to='/pages/premiado/manutencao'
          title='Manutenção'
          hasBullet={true}
          visibleMenuItem={visivelPremiadosVisualizar}
        />
        <SidebarMenuItem
          to='/pages/premiado/anuidade'
          title='Anuidade'
          hasBullet={true}
          visibleMenuItem={visivelPremiadosVisualizar}
        />
        <SidebarMenuItem
          to='/pages/premiado/inatividade'
          title='Inatividade'
          hasBullet={true}
          visibleMenuItem={visivelPremiadosVisualizar}
        />
      </SidebarMenuItemWithSub>
      {/* Campanha */}
      <SidebarMenuItemWithSub
        to='/pages/campanha'
        title='Campanha'
        icon='/media/icons/duotune/general/gen028.svg'
        visibleMenu={visivelCampanha}
      >
        <SidebarMenuItem
          to='/pages/campanha/consultar'
          title='Consultar Campanha'
          hasBullet={true}
          visibleMenuItem={visivelCampanhaVisualizar || visivelCampanhaEditar}
        />
        <SidebarMenuItem
          to='/pages/campanha/cadastrar'
          title='Cadastrar Campanha'
          hasBullet={true}
          visibleMenuItem={visivelCampanhaCadastrar}
        />
      </SidebarMenuItemWithSub>
      {/* Pedido */}
      <SidebarMenuItemWithSub
        to='/pages/pedido'
        title='Pedido'
        icon='/media/icons/duotune/general/gen009.svg'
        visibleMenu={visivelPedido}
      >
        <SidebarMenuItem
          to='/pages/pedido/consultar'
          title='Consultar Pedido'
          hasBullet={true}
          visibleMenuItem={visivelPedidoVisualizar || visivelPedidoEditar}
        />
        <SidebarMenuItem
          to='/pages/pedido/cadastrar'
          title='Cadastrar Pedido'
          hasBullet={true}
          visibleMenuItem={visivelPedidoCadastrar}
        />
      </SidebarMenuItemWithSub>
      {/* Financeiro */}
      <SidebarMenuItemWithSub
        to='/pages/financeiro'
        title='Financeiro'
        icon='/media/icons/duotune/general/gen025.svg'
        visibleMenu={visivelFinanceiro}
      >
        <SidebarMenuItem
          to='/pages/financeiro/liberarCarga'
          title='Liberar Carga'
          hasBullet={true}
          visibleMenuItem={visivelFinanceiroEditar || visivelFinanceiroVisualizar}
        />
        <SidebarMenuItem
          to='/pages/financeiro/liberarCargaEspecial'
          title='Liberar Carga Especial'
          hasBullet={true}
          visibleMenuItem={visivelFinanceiroEditar || visivelFinanceiroVisualizar}
        />
        <SidebarMenuItem
          to='/pages/financeiro/cargaEfetuada'
          title='Carga Efetuada'
          hasBullet={true}
          visibleMenuItem={visivelFinanceiroVisualizar}
        />
        <SidebarMenuItem
          to='/pages/financeiro/controleDeSaldo'
          title='Controle de Saldo'
          hasBullet={true}
          visibleMenuItem={visivelFinanceiroVisualizar}
        />
        {/* <SidebarMenuItem
          to='/pages/financeiro/creditosLiberados'
          title='Créditos Liberados'
          hasBullet={true}
          visibleMenuItem={true}
        /> */}
        <SidebarMenuItem
          to='/pages/financeiro/creditosPendentes'
          title='Créditos Pendentes'
          hasBullet={true}
          visibleMenuItem={visivelFinanceiroVisualizar}
        />
        <SidebarMenuItem
          to='/pages/financeiro/pagamentoDeContas'
          title='Pagamento de Contas'
          hasBullet={true}
          visibleMenuItem={visivelFinanceiroVisualizar}
        />
        <SidebarMenuItem
          to='/pages/financeiro/transferencias'
          title='Transferencias'
          hasBullet={true}
          visibleMenuItem={visivelFinanceiroVisualizar}
        />
        <SidebarMenuItem
          to='/pages/financeiro/recargaDeCelulares'
          title='Recarga de Celulares'
          hasBullet={true}
          visibleMenuItem={visivelFinanceiroVisualizar}
        />
        <SidebarMenuItem
          to='/pages/financeiro/valesPresentes'
          title='Vales Presentes'
          hasBullet={true}
          visibleMenuItem={visivelFinanceiroVisualizar}
        />
        <SidebarMenuItem
          to='/pages/financeiro/ecommerce'
          title='E-commerce'
          hasBullet={true}
          visibleMenuItem={visivelFinanceiroVisualizar}
        />
      </SidebarMenuItemWithSub>
      {/* Loja / Produto */}
      <SidebarMenuItemWithSub
        to='/pages/lojaProduto'
        title='Loja/Produto'
        icon='/media/icons/duotune/general/gen010.svg'
        visibleMenu={visivelLojaProduto}
      >
        <SidebarMenuItem
          to='/pages/lojaProduto/loja/cadastrar'
          title='Adicionar Loja'
          hasBullet={true}
          visibleMenuItem={visivelLojaProdutoCadastrar}
        />
        <SidebarMenuItem
          to='/pages/lojaProduto/loja/'
          title='Consultar Loja'
          hasBullet={true}
          visibleMenuItem={visivelLojaProdutoVisualizar || visivelLojaProdutoEditar}
        />
        <SidebarMenuItem
          to='/pages/lojaProduto/produto/cadastrarProduto/cadastrar'
          title='Adicionar Produto'
          hasBullet={true}
          visibleMenuItem={visivelLojaProdutoCadastrar}
        />
        <SidebarMenuItem
          to='/pages/lojaProduto/produto/consultarProduto'
          title='Consultar Produto'
          hasBullet={true}
          visibleMenuItem={visivelLojaProdutoVisualizar || visivelLojaProdutoEditar}
        />
      </SidebarMenuItemWithSub>
      {/* Relatórios */}
      <SidebarMenuItemWithSub
        to='/pages/relatorio'
        title='Relatórios'
        icon='/media/icons/duotune/abstract/abs015.svg'
        visibleMenu={visivelRelatorios}
      >
        <SidebarMenuItem
          to='/pages/relatorio/relatorioPremiadosCampanha'
          title='Relatório de Premiados Campanha'
          hasBullet={true}
          visibleMenuItem={true}
        />
        <SidebarMenuItem
          to='/pages/relatorio/relatorioExtrato'
          title='Relatório de Extrato'
          hasBullet={true}
          visibleMenuItem={true}
        />
        <SidebarMenuItem
          to='/pages/relatorio/relatorioCreditoPorTitular'
          title='Relatório de Credito por Titular'
          hasBullet={true}
          visibleMenuItem={true}
        />
        <SidebarMenuItem
          to='/pages/relatorio/relatorioTransferenciasEntrePremiados'
          title='Relatório de Transferências Entre Premiados'
          hasBullet={true}
          visibleMenuItem={true}
        />
        <SidebarMenuItem
          to='/pages/relatorio/relatorioDeTarifas'
          title='Relatório de Tarifas'
          hasBullet={true}
          visibleMenuItem={true}
        />
        <SidebarMenuItem
          to='/pages/relatorio/relatorioDeGestoresPorCampanha'
          title='Relatório de Gestores por Campanha'
          hasBullet={true}
          visibleMenuItem={true}
        />
        <SidebarMenuItem
          to='/pages/relatorio/relatorioDeCampanha'
          title='Relatório de Campanhas'
          hasBullet={true}
          visibleMenuItem={true}
        />
      </SidebarMenuItemWithSub>
      {/* Comunicados */}
      <SidebarMenuItemWithSub
        to='/pages/comunicados'
        title='Comunicados'
        icon='/media/icons/duotune/general/gen009.svg'
        visibleMenu={true}
      >
        <SidebarMenuItem
          to='/pages/comunicados/consultar'
          title='Consultar Comunicados'
          hasBullet={true}
          visibleMenuItem={true}
        />
        <SidebarMenuItem
          to='/pages/comunicados/cadastrar'
          title='Cadastrar Comunicados'
          hasBullet={true}
          visibleMenuItem={true}
        />
      </SidebarMenuItemWithSub>
    </>
  )
}

export {SidebarMenuMain}
