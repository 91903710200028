import {FC, useState, useEffect} from 'react'
import {ModalAlterarSenha} from '../../modals/ModalAlterarSenha/ModalAlterarSenha'
import UsuarioViewModel from '../../../../app/vendors/@types/models/UsuarioModel'
import UsuarioParamFilter from '../../../../app/vendors/@types/filters/cadastro/usuarioParamFilter'
import UsuarioController from '../../../../app/modules/cadastro/usuarios/controllers'
import {Link} from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import JwtUtil from '../../../../app/vendors/shared/util/jwt.util'

const Profile: FC = () => {
  const model = new UsuarioViewModel()
  const filter = new UsuarioParamFilter()
  const userController = new UsuarioController(model, filter)

  useEffect(() => {
    userController.init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)

  const {currentUser} = useAuth()
  const jwt = JwtUtil.parseJwt(currentUser)
  
  
  return (
    <>
      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        {/* <Link to='/pages/meuPerfil' className='menu-link px-5'>
          <span className='menu-title position-relative'>Meu Perfil</span>
        </Link> */}
        <a onClick={() => setShowCreateAppModal(true)} className='menu-link px-5'>
          <span className='menu-title position-relative'>Alterar Senha</span>
        </a>
      </div>
      <ModalAlterarSenha
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        email={jwt.email}
        controller={userController}
      />
    </>
  )
}

export {Profile}
