import {TResponse} from '../../vendors/@types/http'
import AbstractService from '../../vendors/services/abstractService'
import {weScoreApi} from '../../vendors/shared/apis/api'
import {IClienteModel} from '../../vendors/@types/models/ClienteModel'

export default class ClienteService extends AbstractService {
  constructor() {
    super(weScoreApi, '/v1/clientes')
  }

  public gridClientes(filter: any): Promise<any> {
    // debugger, page: number, perPage: number
    delete filter.listaCliente

    const output = JSON.parse(JSON.stringify(filter), (_key, value) =>
      value === null || value === '' ? undefined : value
    )
    const filterQueryString = new URLSearchParams(output).toString()
    // const page = 1;${page}ativo=true&
    // headers: {
    //   Authorization: `Bearer ${token}`,
    // },
    return this.api
      .get(
        `${this.rota}/lista-todos-clientes?metaData.pageNumber=1&metaData.pageSize=1000&${filterQueryString}`
      )
      .then((resp) => {
        if (resp.data.statusCode === 200) {
          const _data = resp.data
          return {statusCode: resp.data.statusCode, data: _data, metaData: resp.data.metaData}
        } else if (resp.data.statusCode === 204) {
          return {
            statusCode: resp.data.statusCode,
            data: [],
            metaData: resp.data.metaData,
            message: resp.data.msg,
          }
        }
      })
      .catch((resp) => {
        return {statusCode: 404, data: [], metaData: resp.data.metaData}
      })
  }

  public cadastroClientes(dados: IClienteModel): Promise<TResponse<any>> {
    return this.api
      .post(`${this.rota}/post-cliente`, dados)
      .then((resp) => {
        if (resp.data.statusCode === 204) {
          return {statusCode: resp.data.statusCode, data: null, message: resp.data.msg}
        }
      })
      .catch((resp) => {
        if (resp.data.statusCode === 400) {
          return {statusCode: resp.data.statusCode, data: resp.data, message: 'Erro!'}
        } else {
          return resp.data
        }
      })
  }

  public obterClientePorCNPJ(cnpj: string): Promise<TResponse<IClienteModel>> {
    return this.api
      .get(`${this.rota}/obter-cliente/${cnpj}`)
      .then((resp) => {
        if (resp.data.statusCode === 200) {
          return resp.data
        } else if (resp.data.statusCode === 204) {
          return {
            statusCode: resp.data.statusCode,
            data: [],
            metaData: resp.data.metaData,
            message: resp.data.msg,
          }
        }
      })
      .catch((resp) => {
        //console.clear();
        return {statusCode: 404, data: [], metaData: resp.data.metaData}
      })
  }

  public obterRazaoSocialCliente(): Promise<TResponse<any>> {
    return this.api
      .get(`${this.rota}/d-lista-razao-social?metaData.pageNumber=1&metaData.pageSize=1000`)
      .then((resp) => {
        if (resp.data.statusCode === 200) {
          return resp.data
        } else if (resp.data.statusCode === 204) {
          return {
            statusCode: resp.data.statusCode,
            data: [],
            metaData: resp.data.metaData,
            message: resp.data.msg,
          }
        }
      })
      .catch((resp) => {
        //console.clear();
        return {statusCode: 404, data: [], metaData: resp.data.metaData}
      })
  }

  public obterNomeFantasiaCliente(): Promise<TResponse<any>> {
    return this.api
      .get(`${this.rota}/d-lista-nome-fantasia?metaData.pageNumber=1&metaData.pageSize=1000`)
      .then((resp) => {
        if (resp.data.statusCode === 200) {
          return resp.data
        } else if (resp.data.statusCode === 204) {
          return {
            statusCode: resp.data.statusCode,
            data: [],
            metaData: resp.data.metaData,
            message: resp.data.msg,
          }
        }
      })
      .catch((resp) => {
        //console.clear();
        return {statusCode: 404, data: [], metaData: resp.data.metaData}
      })
  }

  public obterConsultorComercial(): Promise<TResponse<any>> {
    return this.api
      .get(`${this.rota}/d-lista-consultor-comercial?metaData.pageNumber=1&metaData.pageSize=1000`)
      .then((resp) => {
        if (resp.data.statusCode === 200) {
          return resp.data
        } else if (resp.data.statusCode === 204) {
          return {
            statusCode: resp.data.statusCode,
            data: [],
            metaData: resp.data.metaData,
            message: resp.data.msg,
          }
        }
      })
      .catch((resp) => {
        //console.clear();
        return {statusCode: 404, data: [], metaData: resp.data.metaData}
      })
  }

  public obterStatusCliente(): Promise<TResponse<any>> {
    return this.api
      .get(`${this.rota}/d-lista-status?metaData.pageNumber=1&metaData.pageSize=1000`)
      .then((resp) => {
        if (resp.data.statusCode === 200) {
          return resp.data
        } else if (resp.data.statusCode === 204) {
          return {
            statusCode: resp.data.statusCode,
            data: [],
            metaData: resp.data.metaData,
            message: resp.data.msg,
          }
        }
      })
      .catch((resp) => {
        //console.clear();
        return {statusCode: 404, data: [], metaData: resp.data.metaData}
      })
  }

  public obterRazaoSocialPorIdCliente(idCliente: string): Promise<TResponse<any>> {
    return this.api
      .get(`${this.rota}/obter-razao-social/${idCliente}`)
      .then((resp) => {
        if (resp.data.statusCode === 200) {
          return resp.data
        } else if (resp.data.statusCode === 204) {
          return {
            statusCode: resp.data.statusCode,
            data: [],
            metaData: resp.data.metaData,
            message: resp.data.msg,
          }
        }
      })
      .catch((resp) => {
        //console.clear();
        return {statusCode: 404, data: [], metaData: resp.data.metaData}
      })
  }

  public obterConsultorRazaoSocialPorId(id: string | any): Promise<TResponse<any>> {
    return this.api
      .get(`${this.rota}/obter-razao-social/${id}`)
      .then((resp) => {
        if (resp.data.statusCode === 200) {
          return resp.data
        } else if (resp.data.statusCode === 204) {
          return {
            statusCode: resp.data.statusCode,
            data: [],
            metaData: resp.data.metaData,
            message: resp.data.msg,
          }
        }
      })
      .catch((resp) => {
        return {statusCode: 404, data: [], metaData: resp.data.metaData}
      })
  }

  public editarCliente(dados: IClienteModel): Promise<TResponse<IClienteModel>> {
    return this.api
      .put(`${this.rota}/put-cliente`, dados)
      .then((resp) => {
        if (resp.data.statusCode === 204) {
          return {statusCode: resp.data.statusCode, data: null, message: resp.data.msg}
        }
      })
      .catch((resp) => {
        if (resp.data.statusCode === 400) {
          return {statusCode: resp.data.statusCode, data: resp.data, message: 'Erro!'}
        } else {
          return resp.data
        }
      })
  }

  public excluirCliente(cnpj: string): Promise<TResponse<IClienteModel>> {
    return this.api
      .delete(`${this.rota}/delete-cliente/${cnpj}`)
      .then((resp) => {
        if (resp.data.statusCode === 204) {
          return {statusCode: resp.data.statusCode, data: null, message: resp.data.msg}
        }
      })
      .catch((resp) => {
        if (resp.data.statusCode === 400) {
          return {statusCode: resp.data.statusCode, data: resp.data, message: 'Erro!'}
        } else {
          return resp.data
        }
      })
  }
}
