import {Buffer} from 'buffer'

export default class JwtUtil {
  public static parseJwt = (token: string | any) => {    
    let base64Payload = token.accessToken.split('.')[1]
    let payload = Buffer.from(base64Payload, 'base64')
    let access = JSON.parse(payload.toString())
    return access
  }
}
