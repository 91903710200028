
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Dropdown } from '../../content/dropdown/Dropdown'
import clsx from 'clsx'
import { useFormik } from 'formik'
import PedidoController from '../../../../app/modules/pedido/controllers'
import PedidoParamFilter from '../../../../app/vendors/@types/filters/pedido/pedidoParamFilter'
import { IPedidoModel } from '../../../../app/vendors/@types/models/Pedido/PedidoModel'

type Props = {
    show: boolean
    handleClose: () => void
    data: any
    controller: PedidoController
    filter:PedidoParamFilter
}

const ModalPedido: React.FC<Props> = ({ show, handleClose,controller, data,filter }) => {        
    const [idStatusPedido, setIdStatusPedido] = useState<string>('')  
    const [loading, setLoading] = useState(false)  
    const [idPedido, setIdPedido] = useState<string>('')  

    
    useEffect(() => {      
        controller.carregarStatusPedido();        
      }, [])
      
    useEffect(() => {      
        carregarDados(data)
    }, [data])

    useEffect(() => {      
        setIdPedido(data)
    }, [data])
      
    const carregarDados = async (idPedido:string) =>{                
        const dados = await controller.obterPedidoPorId(idPedido);
        setTimeout(() => {                        
            setIdStatusPedido(dados.idStatusPedido)
        }, 1000);
    }

    const form_liberarCarga = useFormik<IPedidoModel>({
        enableReinitialize: true,
        initialValues: data,
        onSubmit: async (values) => {        
            setLoading(true)
            try {            
              setTimeout(() => {                          
                controller.alterarStatusPedido(idPedido,idStatusPedido)
                setLoading(false)
                controller.carregarGridPedido(filter)    
              }, 1000)
            } catch (error) {
              setLoading(false)
            }
          },
    });

    return (
        <>
            <Modal
                id='form_statusPedido'
                dialogClassName='modal-dialog modal-dialog-centered mw-500px'
                show={show}
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Status Pedido</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form
                    id='form_statusPedido'
                    noValidate
                    className='form'
                    onSubmit={form_liberarCarga.handleSubmit}
                >
                    <div className='col-lg-12 fv-row'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                            Status
                        </label>
                        <Dropdown
                            className={clsx('form-select form-select-solid form-select-lg mb-3 mb-lg-0')}
                            placeholder='Selecionar'                            
                            {...form_liberarCarga.getFieldProps('idStatusPedido')}
                            value={idStatusPedido}
                            options={filter.optionStatusPedido}
                            onBlur={form_liberarCarga.handleBlur}
                            onChange={(e: string | any) => {                                
                                setIdStatusPedido(e.target.value)
                              }}
                        />
                    </div>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} >
                        Cancelar
                    </Button>
                    <button
                        className='btn btn-primary fw-bolder px-6 py-3 me-3'
                        type='submit'
                        onClick={() => {                        
                            handleClose(); 
                        }}
                    >
                    Alterar Status
                  </button>
                </Modal.Footer>
                </form>
                </Modal.Body>
                
            </Modal>
        </>
    )
}

export { ModalPedido }
