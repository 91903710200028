/* eslint-disable no-restricted-globals */
import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {requestConfirmEmail} from '../../auth/core/_requests'
import Environment from '../../../../environments/environment'

const initialValues = {}

const ConfirmEmail: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState()

  const searchParams = new URLSearchParams(location.search)
  const [code] = useState<any | string>(searchParams.get('code'))
  const [userId] = useState<any | string>(searchParams.get('userId'))
  const [confirm] = useState<any>(searchParams.get('confirm'))

  const url: any = Environment.getUrlWeb

  const handleRedirect = () => {
    window.location.href = url
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: () => {
      setLoading(true)
      try {
        setTimeout(async () => {
          await requestConfirmEmail({code, userid: userId})
            .then((data: any) => {
              console.log(data)
              if (data) {
                setMessage(data.message)
                handleRedirect()
              } else {
                // já validado
              }
            })
            .catch(() => {
              setLoading(false)
            })
        }, 1000)
      } catch (error) {
        setLoading(false)
      }
    },
  })

  const validar = () => {
    if (
      code !== null &&
      userId !== null &&
      confirm !== null &&
      code !== '' &&
      userId !== '' &&
      confirm !== '' &&
      code !== undefined &&
      userId !== undefined &&
      confirm !== undefined
    ) {
      formik.submitForm()
      return true
    } else {
      console.log('Erro')
      return false
    }
  }

  useEffect(() => {
    if (!formik.isSubmitting) {
      validar()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className='d-flex flex-column flex-root'>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_confirm_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='text-center mb-10'>
            <h1 className='text-dark fw-bolder mb-3'>Confirmação de e-mail</h1>
            <div className='text-gray-500 fw-semibold fs-6'>
              {/* {!loading && <span className='indicator-label'>Salvar</span>} */}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Por favor, espere você será rediredionado...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </div>
            <br />
            <h5 className='text-dark fw-bolder mb-3'>{message}</h5>
          </div>
          {/* begin::Form group */}
          <div className='fv-row mb-8'></div>
          {/* end::Form group */}
          {/* begin::Form group */}
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'></div>
          {/* end::Form group */}
        </form>
      </div>
    </>
  )
}

export {ConfirmEmail}
