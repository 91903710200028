import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const LOGIN_URL = `${API_URL}/v1/login/login`
export const REGISTER_URL = `${API_URL}/v1/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/v1/password/recuperar-senha`
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/token/refresh-token`
export const GET_USER_CONFIRM_EMAIL_URL = `${API_URL}/v1/user/confirm-email`

export async function login(cpf: string, password: string) {
  return await axios
    .post<AuthModel>(LOGIN_URL, {
      cpf,
      password,
    })
    .then((resp) => {
      return resp.data
    })
    .catch((resp) => resp.data)
}

export function register(
  cpf: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    cpf,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

export function requestPassword(email: string) {
  return axios
    .get(`${REQUEST_PASSWORD_URL}?email=${email}`)
    .then((resp) => {
      if (resp.data.statusCode === 200) {
        return resp.data
      } else if (resp.data.statusCode === 204) {
        return {
          statusCode: resp.data.statusCode,
          data: [],
          metaData: resp.data.metaData,
          message: resp.data.msg,
        }
      }
    })
    .catch((resp) => {
      return {statusCode: 404, data: [], metaData: resp.data.metaData}
    })
}

export async function requestConfirmEmail({code, userid}: {code: string; userid: string}) {
  return axios
    .get(`${GET_USER_CONFIRM_EMAIL_URL}?code=${code}&userId=${userid}`)
    .then((resp) => {
      console.log('resp', resp)
      if (resp.data.statusCode === 200) {
        console.log('resp 200', resp.data)
        return resp.data
      } else if (resp.data.statusCode === 204) {
        console.log('resp 204', resp.data)
        return {
          statusCode: resp.data.statusCode,
          data: [],
          metaData: resp.data.metaData,
          message: resp.data.msg,
        }
      }
    })
    .catch((resp) => {
      return {statusCode: 404, data: [], metaData: resp.data.metaData}
    })
}

export async function getUserByRefreshToken(token: string) {
  return await axios
    .post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
      refreshToken: token,
    })
    .then((resp) => {
      return resp.data
    })
    .catch((resp) => resp.data)
}
