export interface IPasswordModel {
    emailResetar?: string,
    senhaResetar?: string,
    confirmacaoSenhaResetar?: string,
}

export const PasswordModel: IPasswordModel = {
    emailResetar: '',
    senhaResetar: '',
    confirmacaoSenhaResetar: '',
}
