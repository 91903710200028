import React, {useState} from 'react'
import * as Yup from 'yup'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {useFormik} from 'formik'
import UsuarioController from '../../../../app/modules/cadastro/usuarios/controllers'
import clsx from 'clsx'
import {
  IPasswordModel,
  PasswordModel as initialValuesPassword,
} from '../../../../app/vendors/@types/models/PasswordModel'
import ToastService from '../../../../app/vendors/services/toastService'

type Props = {
  show: boolean
  handleClose: () => void
  email: any
  controller: UsuarioController
}

const alterarSenhaSchema = Yup.object().shape({
  senhaResetar: Yup.string().required('Preencha o senha!'),
  confirmacaoSenhaResetar: Yup.string().required('Preencha o confirmação senha!'),
})

const ModalAlterarSenha: React.FC<Props> = ({show, handleClose, email, controller}) => {
  const [dataResetarSenha, setDataResetarSenha] = useState<IPasswordModel>(initialValuesPassword)
  const [loading, setLoading] = useState(false)

  const form_alterarSenha = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesPassword,
    validationSchema: alterarSenhaSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        const _dataResetarSenha = Object.assign(dataResetarSenha, values)
        if (dataResetarSenha.senhaResetar !== dataResetarSenha.confirmacaoSenhaResetar) {
          ToastService.showInfo('A senha e a confirmação de senha estão incorretos!')
        } else {
          setTimeout(() => {
            dataResetarSenha.emailResetar = email
            controller.resetarSenha(_dataResetarSenha)
            setLoading(false)
          }, 1500)
        }
      } catch (error) {
        setLoading(false)
      }
    },
  })

  return (
    <>
      <Modal
        id='kt_modal_permissao'
        dialogClassName='modal-dialog modal-dialog-centered mw-500px'
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Alterar Senha</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            id='form_alterarSenha'
            noValidate
            className='form'
            onSubmit={form_alterarSenha.handleSubmit}
          >
            <input type='hidden' {...form_alterarSenha.getFieldProps('emailResetar')} />
            <div className='col-lg-12'>
              <div className='row'>
                <div className='col-lg-12 fv-row'>
                  <label className='col-lg-12 col-form-label fw-bold fs-6'>Senha</label>
                  <input
                    type='password'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      {
                        'is-invalid':
                          form_alterarSenha.touched.senhaResetar &&
                          form_alterarSenha.errors.senhaResetar,
                      },
                      {
                        'is-valid':
                          form_alterarSenha.touched.senhaResetar &&
                          !form_alterarSenha.errors.senhaResetar,
                      }
                    )}
                    placeholder='Senha'
                    {...form_alterarSenha.getFieldProps('senhaResetar')}
                    onChange={form_alterarSenha.handleChange}
                    onBlur={form_alterarSenha.handleBlur}
                  />
                  {form_alterarSenha.touched.senhaResetar && form_alterarSenha.errors.senhaResetar && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{form_alterarSenha.errors.senhaResetar}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-12 fv-row'>
                  <label className='col-lg-12 col-form-label fw-bold fs-6'>Confirmar Senha</label>
                  <input
                    type='password'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      {
                        'is-invalid':
                          form_alterarSenha.touched.confirmacaoSenhaResetar &&
                          form_alterarSenha.errors.confirmacaoSenhaResetar,
                      },
                      {
                        'is-valid':
                          form_alterarSenha.touched.confirmacaoSenhaResetar &&
                          !form_alterarSenha.errors.confirmacaoSenhaResetar,
                      }
                    )}
                    placeholder='Senha'
                    {...form_alterarSenha.getFieldProps('confirmacaoSenhaResetar')}
                    onChange={form_alterarSenha.handleChange}
                    onBlur={form_alterarSenha.handleBlur}
                  />
                  {form_alterarSenha.touched.confirmacaoSenhaResetar &&
                    form_alterarSenha.errors.confirmacaoSenhaResetar && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          {form_alterarSenha.errors.confirmacaoSenhaResetar}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <Modal.Footer>
              <Button variant='secondary' onClick={handleClose}>
                Cancelar
              </Button>
              <button
                className='btn btn-success fw-bolder px-6 py-3 me-3'
                type='submit'
                onClick={() => {}}
              >
                Alterar
              </button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {ModalAlterarSenha}
