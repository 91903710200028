import { useState } from 'react'
import { IPermissaoModel } from './PermissaoModel'

export interface IUsuarioModel {
    id: string,
    email: string,
    nomeCompleto: string,
    idDepartamentoUsuario: string | null,
    cpf: string,
    dataNascimento: string | Date,
    rgs: string,
    celular: string,
    telefone1: string,
    telefone2: string,
    endereco: string,
    numero: string,
    complemento: string,
    bairro: string,
    cidade: string,
    uf: string,
    cep: string,
    idPerfil: string,
    perfil: string,
    idClientes: string,
    nomeFantasia: string,
    permissao: string,
    cnpj: string,
    usuario: string,
    senha: string,
    ativo: boolean | any,
    numeroConta:string,
    dataCadastro: string,    
    dataAtualizacao: string,
    idPermissao:IPermissaoModel[]
}

export const UsuarioModel: IUsuarioModel = {
    id: '',
    email: '',
    nomeCompleto: '',
    idDepartamentoUsuario: '',
    cpf: '',
    dataNascimento: '',
    rgs: '',
    telefone1: '',
    telefone2: '',
    celular: '',
    endereco: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    uf: '',
    cep: '',
    idPerfil: '',
    perfil: '',
    idClientes: '',
    nomeFantasia: '',
    permissao: '',
    cnpj: '',
    usuario: '',
    senha: '',
    numeroConta:'',
    ativo: false || '',
    dataCadastro: '',
    dataAtualizacao: '',
    idPermissao:[]
}

export interface IUsuarioGrid {
    id: string,
    nomeCompleto: string,
    cpf: string,
    ativo: boolean | any
    email: string,
}

export const UsuarioGrid: IUsuarioGrid = {
    id: '',
    nomeCompleto: '',
    cpf: '',
    ativo: null,
    email: '',
}

export default class UsuarioViewModel {

    public dadosUsuario: IUsuarioModel;
    public setDadosUsuario: any;

    constructor() {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [this.dadosUsuario, this.setDadosUsuario] = useState<IUsuarioModel>(UsuarioModel);
    }
}
