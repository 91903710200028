import { toast } from 'react-toastify'
//import 'react-toastify/dist/ReactToastify.css'
import 'react-toastify/dist/ReactToastify.min.css'

export default class ToastService {

  //static toastView: any = toast;
  static AUTOCLOSE: number = 2000;
  static THEME: any = 'light';

  public static init() { }

  public static showSuccess(message: string | null) {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: this.AUTOCLOSE,
      theme: this.THEME,
    });
  }

  public static showInfo(message: string | null) {
    toast.info(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: this.AUTOCLOSE,
      theme: "light",
    });
  }

  public static showWarn(message: string | null) {
    toast.warn(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: this.AUTOCLOSE,
      theme: "light",
    });
  }

  public static showError(message: string | null) {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: this.AUTOCLOSE,
      theme: "light"
    });
  }

  // public static _show(_classe: string, _message: string | null) {
  //   if (!_message) {
  //     return;
  //   }
  //   toast(_message, {
  //     className: toast.TYPE.SUCCESS,
  //     position: toast.POSITION.TOP_RIGHT
  //   });
  // }
}
