import { TResponse } from '../../vendors/@types/http'
import AbstractService from '../../vendors/services/abstractService'
import { weScoreApi } from '../../vendors/shared/apis/api'
import { IUsuarioModel } from '../../vendors/@types/models/UsuarioModel'

export default class UsuarioService extends AbstractService {

    constructor() {
        super(weScoreApi, '/v1/user');
    }

    public gridUsuarios(filter: any): Promise<any> {
        delete filter.listaUsuario;

        const output = JSON.parse(JSON.stringify(filter), (_key, value) =>
            value === null || value === '' ? undefined : value,
        );
        const filterQueryString = new URLSearchParams(output).toString();

        return this.api
            .get(`${this.rota}/lista-todos-users?metaData.pageNumber=1&metaData.pageSize=1000&${filterQueryString}`)
            .then((resp) => {
                if (resp.data.statusCode === 200) {
                    const _data = resp.data.data;
                    return { statusCode: resp.data.statusCode, data: _data, metaData: resp.data.metaData }
                } else if (resp.data.statusCode === 204) {
                    return { statusCode: resp.data.statusCode, data: [], metaData: resp.data.metaData, message: resp.data.msg }
                }
            })
            .catch((resp) => {
                return { statusCode: 404, data: [], metaData: resp.data.metaData }
            });
    }

    public cadastroUsuario(dados: any): Promise<TResponse<IUsuarioModel>> {
        return this.api
            .post(`${this.rota}/post-users`, dados)
            .then((resp) => {
                if (resp.data.statusCode === 204) {
                    return { statusCode: resp.data.statusCode, data: null, message: resp.data.msg }
                }
            })
            .catch((resp) => {
                if (resp.data.statusCode === 400) {
                    return { statusCode: resp.data.statusCode, data: resp.data, message: "Erro!" }
                } else {
                    return resp.data
                }
            });
    }

    public obterDepartamento(): Promise<TResponse<any>> {
        return this.api
            .get(`${this.rota}/d-lista-departamento?metaData.pageNumber=1&metaData.pageSize=10`)
            .then((resp) => {
                if (resp.data.statusCode === 200) {
                    return resp.data;
                } else if (resp.data.statusCode === 204) {
                    return { statusCode: resp.data.statusCode, data: [], metaData: resp.data.metaData, message: resp.data.msg }
                }
            })
            .catch((resp) => {
                //console.clear();
                return { statusCode: 404, data: [], metaData: resp.data.metaData }
            });
    }

    public obterUsuario(): Promise<TResponse<any>> {
        return this.api
            .get(`${this.rota}/d-lista-nome-users?metaData.pageNumber=1&metaData.pageSize=10000`)
            .then((resp) => {
                if (resp.data.statusCode === 200) {
                    return resp.data;
                } else if (resp.data.statusCode === 204) {
                    return { statusCode: resp.data.statusCode, data: [], metaData: resp.data.metaData, message: resp.data.msg }
                }
            })
            .catch((resp) => {
                //console.clear();
                return { statusCode: 404, data: [], metaData: resp.data.metaData }
            });
    }

    public obterUsuarioPorEmail(email: string): Promise<TResponse<IUsuarioModel>> {
        return this.api
            .get(`${this.rota}/obter-users/${email}`)
            .then((resp) => {                
                if (resp.data.statusCode === 200) {
                    return resp.data;
                } else if (resp.data.statusCode === 204) {
                    return { statusCode: resp.data.statusCode, data: [], metaData: resp.data.metaData, message: resp.data.msg }
                }
            })
            .catch((resp) => {
                //console.clear();
                return { statusCode: 404, data: [], metaData: resp.data.metaData }
            });
    }

    public obterUsuarioPorId(userId: string,numeroConta:string): Promise<TResponse<IUsuarioModel>> {
        return this.api
            .get(`${this.rota}/obter-user?userId=${userId}&numeroConta=${numeroConta}`)
            .then((resp) => {                
                if (resp.data.statusCode === 200) {
                    return resp.data;
                } else if (resp.data.statusCode === 204) {
                    return { statusCode: resp.data.statusCode, data: [], metaData: resp.data.metaData, message: resp.data.msg }
                }
            })
            .catch((resp) => {
                //console.clear();
                return { statusCode: 404, data: [], metaData: resp.data.metaData }
            });
    }

    public obterConsultorComercial(): Promise<TResponse<any>> {
        return this.api
            .get(`${this.rota} /d-lista-Usuario?metaData.pageNumber=1&metaData.pageSize=10`)
            .then((resp) => {
                if (resp.data.statusCode === 200) {
                    return resp.data;
                } else if (resp.data.statusCode === 204) {
                    return { statusCode: resp.data.statusCode, data: [], metaData: resp.data.metaData, message: resp.data.msg }
                }
            })
            .catch((resp) => {
                //console.clear();
                return { statusCode: 404, data: [], metaData: resp.data.metaData }
            });
    }

    public editarUsuario(dados: IUsuarioModel): Promise<TResponse<IUsuarioModel>> {
        return this.api
            .put(`${this.rota}/put-users`, dados)
            .then((resp) => {                                
                if (resp.data.statusCode === 200) {
                    return resp.data;
                } else if (resp.data.statusCode === 204) {
                    return { statusCode: resp.data.statusCode, data: [], metaData: resp.data.metaData, message: resp.data.msg }
                }
            })
            .catch((resp) => {
                if (resp.data.statusCode === 400) {
                    return { statusCode: resp.data.statusCode, data: resp.data, message: "Erro!" }
                } else {
                    return resp.data
                }
            });
    }

    public excluirUsuario(email: string): Promise<TResponse<IUsuarioModel>> {
        return this.api
            .delete(`${this.rota}/delete-usuario/${email}`)
            .then((resp) => {
                if (resp.data.statusCode === 204) {
                    return { statusCode: resp.data.statusCode, data: null, message: resp.data.msg }
                }
            })
            .catch((resp) => {
                if (resp.data.statusCode === 400) {
                    return { statusCode: resp.data.statusCode, data: resp.data, message: "Erro!" }
                } else {
                    return resp.data
                }
            });
    }
}
