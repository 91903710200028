import {AuthModel} from './_models'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {weScoreApi} from '../../../vendors/shared/apis/api'

const API_URL = process.env.REACT_APP_API_URL
const AUTH_LOCAL_STORAGE_KEY = 'auth-react'
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/token/refresh-token`

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    //console.log('getAuth', lsValue)
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      //console.log('getAuth', auth)
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  // const token = localStorage.getItem('auth-react')
  // console.log('setupAxios', token)

  // weScoreApi.interceptors.request.use(
  // (config: any) => {
  //   const token = localStorage.getItem('auth-react')
  //   //console.log('setupAxios 1')
  //   if (token) {
  //     config.headers.Authorization = `Bearer ${token}`
  //   }
  //   return config
  // },
  //(error: any) => Promise.reject(error)
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      //console.log('setupAxios 1')
      const auth = getAuth()
      //console.log('config auth', auth)
      if (auth && auth.accessToken) {
        config.headers.Authorization = `Bearer ${auth.accessToken}`
      }
      //console.log('config')
      return config
    },
    (err: any) => Promise.reject(err)
  )
  //)
}

export function setupAxiosRefreshToken(axios: any) {
  //console.log('setupAxiosRefreshToken')
  axios.defaults.headers.Accept = 'application/json'
  // Add a response interceptor to refresh the JWT token if it's expired
  axios.interceptors.response.use(
    (response: any) => response,
    (error: any) => {
      const originalRequest = error.config

      // If the error is a 401 and we have a refresh token, refresh the JWT token
      if (error.response.status === 401 && sessionStorage.getItem('refreshToken')) {
        const refreshToken = sessionStorage.getItem('refreshToken')

        let data = JSON.stringify({
          refresh_token: refreshToken,
        })

        axios
          .post('/refreshToken', data)
          .then((response: any) => {
            sessionStorage.setItem('jwtToken', response.token)
            sessionStorage.setItem('refreshToken', response.refresh_token)

            // Re-run the original request that was intercepted
            originalRequest.headers.Authorization = `Bearer ${response.token}`
            axios(originalRequest)
              .then((response: any) => {
                return response.data
              })
              .catch((error: any) => {
                console.log(error)
              })
            // return api(originalRequest)
          })
          .catch((err: any) => {
            // If there is an error refreshing the token, log out the user
            console.log(err)
          })
      }

      // Return the original error if we can't handle it
      return Promise.reject(error)
    }
  )
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
