import AbstractController from '../../../../vendors/services/abstractController'
import UsuarioViewModel, {IUsuarioModel} from '../../../../vendors/@types/models/UsuarioModel'
import {IPasswordModel} from '../../../../vendors/@types/models/PasswordModel'
import UsuarioParamFilter from '../../../../vendors/@types/filters/cadastro/usuarioParamFilter'
import UsuarioService from '../../../../services/cadastro/UsuarioService'
import ClienteService from '../../../../services/cadastro/ClienteService'
import PerfilService from '../../../../services/PerfilService'
import DepartamentoService from '../../../../services/DepartamentoService'
import PasswordService from '../../../../services/PasswordService'
import ToastService from '../../../../vendors/services/toastService'
import {Mensagem} from '../../../../vendors/shared/mensagem/Mensagem'
import {Utils} from '../../../../vendors/shared/util/Utils'
import {useNavigate} from 'react-router-dom'
import CepService from '../../../../services/CepService'
import {ICEP} from '../../../../vendors/@types/models/EnderecoEntregaModel'

export default class UsuarioController extends AbstractController {
  private _cepService = new CepService()
  private _usuarioService = new UsuarioService()
  private _clienteService = new ClienteService()
  private _perfilService = new PerfilService()
  private _departamentoService = new DepartamentoService()
  private _passwordService = new PasswordService()

  public navigate = useNavigate()

  public model: UsuarioViewModel
  public filter: UsuarioParamFilter

  constructor(model: UsuarioViewModel, filter: UsuarioParamFilter) {
    super()
    this.model = model
    this.filter = filter
  }

  private recarregaGrid = () => {
    const filtroRecarrega: UsuarioParamFilter = {...this.filter}
    this.carregarGridUsuario(filtroRecarrega)
  }

  public pesquisar = () => {
    const filtro: UsuarioParamFilter = {...this.filter}
    if (filtro.dataInicio !== null) {
      filtro.dataInicio = Utils.formatarSQL(filtro.dataInicio)
    }
    if (filtro.dataFim !== null) {
      filtro.dataFim = Utils.formatarSQL(filtro.dataFim)
    }
    if (filtro.cpf !== null) {
      filtro.cpf = Utils.removerMascara(filtro.cpf)
    }

    const filtros = {
      nomeUsuario: filtro.nomeUsuario,
      cpf: filtro.cpf,
      idCliente: filtro.idCliente,
      idPerfil: filtro.idPerfil,
      ativo: filtro.ativo,
      dataInicio: filtro.dataInicio,
      dataFim: filtro.dataFim,
    }

    this.carregarGridUsuario(filtros)
  }

  public buscarCEP = async (e: string) => {
    this._clienteService.blockUIService.start()
    const resp = await this._cepService.obterCep(e)
    if (resp?.data) {
      const buscarCEP: ICEP = {
        endereco: resp.data.street,
        bairro: resp.data.neighborhood,
        cidade: resp.data.city,
        uf: resp.data.stateInitials,
      }

      this.filter.setEndereco(buscarCEP.endereco)
      this.filter.setBairro(buscarCEP.bairro)
      this.filter.setCidade(buscarCEP.cidade)
      this.filter.setUf(buscarCEP.uf)

      return buscarCEP
    }
    this._clienteService.blockUIService.stop()
  }

  public carregarNomeFantasiaCliente = async () => {
    this._clienteService.blockUIService.start()
    await this._clienteService
      .obterNomeFantasiaCliente()
      .then((resp: any) => {
        let arr = Utils.montaOptions(resp?.data)
        this.filter.setOptionNomeFantasia(arr)
        this._clienteService.blockUIService.stop()
      })
      .catch((err) => {
        this._clienteService.blockUIService.stop()
        ToastService.showError(Mensagem.FALHA_REQUISICAO)
      })
  }

  public carregarGridUsuario = async (filtro: any) => {
    this._usuarioService.blockUIService.start()
    await this._usuarioService
      .gridUsuarios(filtro)
      .then((res) => {
        let tabela: any = []
        if (!!res.data && res.data.length > 0) {
          tabela = res.data?.map((param: any) => {
            return {
              id: param.id,
              nomeCompleto: `${param.nome}`,
              cpf: Utils.mascaraCPF(param.cpf),
              email: param.userName,
              ativo: param.ativoUsuario,
            }
          })
        }
        this.filter.setListaUsuario(tabela)
        this._usuarioService.blockUIService.stop()
      })
      .catch((err) => {
        console.log(err)
        this._usuarioService.blockUIService.stop()
        ToastService.showError(Mensagem.FALHA_REQUISICAO)
      })
  }

  public cadastrarUsuario = async (usuario: IUsuarioModel) => {
    const usuarioModel: any = {
      email: usuario.email,
      nome: usuario.nomeCompleto,
      idDepartamentoUsuario: usuario.idDepartamentoUsuario,
      cpf: Utils.removerMascara(usuario.cpf),
      dataNascimento: Utils.formatarSQL(usuario.dataNascimento),
      rg: Utils.removerMascara(usuario.rgs),
      telefone1: Utils.removerMascara(usuario.telefone1),
      telefone2: Utils.removerMascara(usuario.telefone2),
      celular: Utils.removerMascara(usuario.celular),
      idPerfil: usuario.idPerfil,
      idClientes: usuario.idClientes,
      endereco: usuario.endereco,
      numero: usuario.numero,
      complemento: usuario.complemento,
      bairro: usuario.bairro,
      cidade: usuario.cidade,
      uf: usuario.uf,
      cep: Utils.removerMascara(usuario.cep),
      nomeFantasia: usuario.nomeFantasia,
      cnpj: Utils.removerMascara(usuario.cnpj),
      usuario: usuario.usuario,
      senha: usuario.senha,
      ativo: usuario.ativo,
      idPermissao: usuario.idPermissao.length ? usuario.idPermissao : null,
    }

    this._usuarioService.blockUIService.start()

    await this._usuarioService
      .cadastroUsuario(usuarioModel)
      .then((res) => {
        if (res.statusCode === 204) {
          ToastService.showSuccess(res.message)
          setTimeout(() => {
            this.navigate('../../../usuario')
          }, 3000)
        } else {
          if (!!res.data) {
            res.data.errors.forEach((item: any) => {
              ToastService.showError(item.userMessage)
            })
          }
        }
        this._usuarioService.blockUIService.stop()
      })
      .catch((err) => {
        console.log(err)
        this._usuarioService.blockUIService.stop()
        ToastService.showError(Mensagem.FALHA_REQUISICAO)
      })
  }

  public carregarRazaoSocial = async () => {
    this._clienteService.blockUIService.start()
    await this._clienteService
      .obterRazaoSocialCliente()
      .then((resp: any) => {
        let arr = Utils.montaOptions(resp.data)
        this.filter.setOptionRazaoSocial(arr)
        this._clienteService.blockUIService.stop()
      })
      .catch((err) => {
        console.log(err)
        this._clienteService.blockUIService.stop()
        ToastService.showError(Mensagem.FALHA_REQUISICAO)
      })
  }

  public carregarTipoPerfil = async () => {
    this._perfilService.blockUIService.start()
    await this._perfilService
      .obterPerfil()
      .then((resp: any) => {
        let arr = Utils.montaOptions(resp.data)
        this.filter.setOptionTipoPerfil(arr)
        this._perfilService.blockUIService.stop()
      })
      .catch((err) => {
        console.log(err)
        this._perfilService.blockUIService.stop()
        ToastService.showError(Mensagem.FALHA_REQUISICAO)
      })
  }

  public carregarPermissao = async () => {
    this._perfilService.blockUIService.start()
    await this._perfilService
      .obterPermissao()
      .then((resp: any) => {
        let arr = Utils.montaOptionsLabel(resp.data)
        this.filter.setOptionPermissao(arr)
        this._perfilService.blockUIService.stop()
      })
      .catch((err) => {
        console.log(err)
        this._perfilService.blockUIService.stop()
        ToastService.showError(Mensagem.FALHA_REQUISICAO)
      })
  }

  public carregarDepartamento = async () => {
    this._departamentoService.blockUIService.start()
    await this._departamentoService
      .obterDepartamentoUsuario()
      .then((resp: any) => {
        let arr = Utils.montaOptions(resp.data)
        this.filter.setOptionDepartamento(arr)
        this._departamentoService.blockUIService.stop()
      })
      .catch((err) => {
        console.log(err)
        this._departamentoService.blockUIService.stop()
        ToastService.showError(Mensagem.FALHA_REQUISICAO)
      })
  }

  public obterUsuarioPorEmail = async (email: string | any) => {
    this._usuarioService.blockUIService.start()
    await this._usuarioService
      .obterUsuarioPorEmail(email)
      .then((resp: any) => {
        this.buscarCEP(resp.data.cep)
        this.model.setDadosUsuario(resp.data)
        if (resp.data.idClientes != null) {
          this.obterConsultorRazaoSocialPorId(resp.data.idClientes)
          if (resp.data.idPerfil === 'ffa1f523-d659-4607-9d45-39f38aaaea5f') {
            this.filter.isVisualizarPermissao = true
          } else {
            this.filter.isVisualizarPermissao = false
          }
        }
      })
      .catch((err) => {
        console.log(err)
        this._usuarioService.blockUIService.stop()
        ToastService.showError(Mensagem.FALHA_REQUISICAO)
      })
  }

  public resetarSenha = async (dados: IPasswordModel) => {
    const resetarModel: IPasswordModel | any = {
      email: dados.emailResetar,
      senha: dados.senhaResetar,
      confirmacaoSenha: dados.senhaResetar,
    }

    this._passwordService.blockUIService.start()
    await this._passwordService
      .resetarSenha(resetarModel)
      .then((resp: any) => {
        if (resp.statusCode === 204) {
          ToastService.showSuccess(resp.message)
          setTimeout(() => {
            window.location.reload()  
          }, 1500);         
          
        } else {
          if (!!resp.data) {
            resp.data.errors.forEach((item: any) => {
              ToastService.showError(item.userMessage)
            })
          } else {
            resp.data.errors.forEach((item: any) => {
              ToastService.showSuccess(item.userMessage)
            })
          }
        }        
        this._passwordService.blockUIService.stop()
      })
      .catch((err) => {
        console.log(err)
        this._passwordService.blockUIService.stop()
        ToastService.showError(Mensagem.FALHA_REQUISICAO)
      })
  }

  public obterConsultorRazaoSocialPorId = async (id: string | any) => {
    this._clienteService.blockUIService.start()
    await this._clienteService
      .obterConsultorRazaoSocialPorId(id)
      .then((resp: any) => {
        this.filter.setNomeFantasia(resp.data.nomeFantasia)
        this.filter.setCnpj(resp.data.cnpj)
      })
      .catch((err) => {
        console.log(err)
        this._clienteService.blockUIService.stop()
        ToastService.showError(Mensagem.FALHA_REQUISICAO)
      })
  }

  public editarUsuario = async (usuario: IUsuarioModel) => {
    const usuarioModel: any = {
      id: usuario.id,
      email: usuario.email,
      nome: usuario.nomeCompleto,
      idDepartamentoUsuario: usuario.idDepartamentoUsuario,
      cpf: Utils.removerMascara(usuario.cpf),
      dataNascimento: Utils.formatarSQL(usuario.dataNascimento),
      rg: Utils.removerMascara(usuario.rgs),
      celular: Utils.removerMascara(usuario.celular),
      telefone1: Utils.removerMascara(usuario.telefone1),
      telefone2: Utils.removerMascara(usuario.telefone2),
      idPerfil: usuario.idPerfil,
      idClientes: usuario.idClientes,
      endereco: usuario.endereco,
      numero: usuario.numero,
      complemento: usuario.complemento,
      bairro: usuario.bairro,
      cidade: usuario.cidade,
      uf: usuario.uf,
      cep: Utils.removerMascara(usuario.cep),
      nomeFantasia: usuario.nomeFantasia,
      cnpj: Utils.removerMascara(usuario.cnpj),
      usuario: usuario.usuario,
      senha: usuario.senha,
      ativo: usuario.ativo,
      idPermissao: usuario.idPermissao.length === 0 ?null:usuario.idPermissao,
    }

    this._usuarioService.blockUIService.start()

    await this._usuarioService
      .editarUsuario(usuarioModel)
      .then((resp) => {
        if (resp.statusCode === 204) {
          ToastService.showSuccess(resp.message)
          setTimeout(() => {
            this.navigate('../../../usuario')
          }, 3000)
        } else {
          if (!!resp.data) {
            resp.data.errors.forEach((item: any) => {
              ToastService.showError(item.userMessage)
            })
          }
        }
        this._usuarioService.blockUIService.stop()
      })
      .catch((err) => {
        console.log(err)
        this._usuarioService.blockUIService.stop()
        ToastService.showError(Mensagem.FALHA_REQUISICAO)
      })
  }

  public excluirUsuario = async (cnpj: string) => {
    this._usuarioService.blockUIService.start()
    await this._usuarioService
      .excluirUsuario(cnpj)
      .then((resp: any) => {
        ToastService.showSuccess(resp.message)
        this.recarregaGrid()
        this._usuarioService.blockUIService.stop()
      })
      .catch((err) => {
        console.log(err)
        this._usuarioService.blockUIService.stop()
        ToastService.showError(Mensagem.FALHA_REQUISICAO)
      })
  }
}
