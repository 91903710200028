import { TResponse } from "../vendors/@types/http";
import AbstractService from "../vendors/services/abstractService";
import { weScoreApi } from "../vendors/shared/apis/api";

export default class CepService extends AbstractService {

    constructor() {
        super(weScoreApi, '/v1/cep');
    }

    public obterCep(cep: string): Promise<TResponse<any>> {
        cep.replace("-", "")
        return this.api
            .get(`${this.rota}/${cep}`)
            .then((resp) => {
                if (resp.data.statusCode === 200) {
                    return resp.data;
                } else if (resp.data.statusCode === 204) {
                    return { statusCode: resp.data.statusCode, data: null, metaData: resp.data.metaData, message: resp.data.msg }
                }
            })
            .catch((resp) => {
                return { statusCode: 404, data: [], metaData: resp.data.metaData }
            });
    }
}
