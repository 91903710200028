/* eslint-disable */
import {useState} from 'react'
import {IUsuarioGrid} from '../../models/UsuarioModel'

interface iOption {
  value: string | any | number
  name: string | any
}

export default class UsuarioParamFilter {
  public nomeUsuario: any
  public setNomeUsuario: any

  public cpf: any
  public setCpf: any

  public nomeFantasia: string | any
  public setNomeFantasia: any

  public cnpj: string | any
  public setCnpj: any

  public razaoSocial: any
  public setRazaoSocial: any

  public idCliente: any
  public setIdCliente: any

  public idPerfil: string | any
  public setIdPerfil: any

  public ativo: boolean | any
  public setAtivo: any

  public dataInicio: Date | any | undefined
  public setDataInicio: Date | any

  public dataFim: Date | any | undefined
  public setDataFim: Date | any

  public optionNomeFantasia: any
  public setOptionNomeFantasia: any

  public optionDepartamento: any
  public setOptionDepartamento: any

  public optionRazaoSocial: any
  public setOptionRazaoSocial: any

  public optionTipoPerfil: any[]
  public setOptionTipoPerfil: any

  public optionPermissao: any[]
  public setOptionPermissao: any

  public listaUsuario: IUsuarioGrid[]
  public setListaUsuario: any

  public cep: string | undefined
  public setCep: any

  public endereco: string | undefined
  public setEndereco: any

  public bairro: string | undefined
  public setBairro: any

  public cidade: string | undefined
  public setCidade: any

  public uf: string | undefined
  public setUf: any

  public isVisualizarPermissao:boolean;
  public setIsVisualizarPermissao:any;

  constructor() {
    ;[this.nomeUsuario, this.setNomeUsuario] = useState(null)
    ;[this.cpf, this.setCpf] = useState(null)
    ;[this.idCliente, this.setIdCliente] = useState<any>()
    ;[this.nomeFantasia, this.setNomeFantasia] = useState<string | any>()
    ;[this.cnpj, this.setCnpj] = useState<string | any>()
    ;[this.idPerfil, this.setIdPerfil] = useState<string>()
    ;[this.ativo, this.setAtivo] = useState<boolean>()
    ;[this.dataInicio, this.setDataInicio] = useState(null)
    ;[this.dataFim, this.setDataFim] = useState(null)
    ;[this.optionNomeFantasia, this.setOptionNomeFantasia] = useState<iOption[]>([])
    ;[this.optionDepartamento, this.setOptionDepartamento] = useState<iOption[]>([])
    ;[this.optionRazaoSocial, this.setOptionRazaoSocial] = useState<iOption[]>([])
    ;[this.optionTipoPerfil, this.setOptionTipoPerfil] = useState<iOption[]>([])
    ;[this.optionPermissao, this.setOptionPermissao] = useState<iOption[]>([])
    ;[this.listaUsuario, this.setListaUsuario] = useState<IUsuarioGrid[]>([])
    ;[this.cep, this.setCep] = useState<string>()
    ;[this.endereco, this.setEndereco] = useState<string>()
    ;[this.bairro, this.setBairro] = useState<string>()
    ;[this.cidade, this.setCidade] = useState<string>()
    ;[this.uf, this.setUf] = useState<string>()
    ;[this.isVisualizarPermissao, this.setIsVisualizarPermissao] = useState<boolean>(false)
  }
}
