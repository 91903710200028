/* eslint-disable */
import Moment from 'moment'
import {Mensagem} from '../mensagem/Mensagem'
import {Validacoes} from '../validacoes/Validacoes'
import {IMenuModel} from '../../@types/models/MenuModel'
import {useAuth} from '../../../modules/auth'
import JwtUtil from './jwt.util'

export class Utils {

  public static ROLE_MASTER = 'WeScore Master'
  public static ROLE = 'Equipe WeScore'
  public static AUTH_MENU = [
    'Cadastro',
    'Premiados',
    'Campanha',
    'Pedidos',
    'Financeiro',
    'Loja/Produto',
    'Relatórios',
    'Comunicados',
  ]
  public static ACAO_CADASTRAR = 'Cadastrar'
  public static ACAO_EDITAR = 'Editar'
  public static ACAO_EXCLUIR = 'Excluir'
  public static ACAO_VISUALIZAR = 'Visualizar'
  public static ACAO_LIBERAR = 'Liberar'

  public static formatarDataBr(data: any) {
    if (!data || data === '') {
      return ''
    }
    return Moment(data).format('DD/MM/YYYY')
  }

  public static formatarSQL(data: any) {
    Moment.locale('en')
    return Moment(data).format('YYYY-MM-DD')
  }

  public static gerarDataHoraBr() {
    return Moment().format('DD/MM/YYYY HH:mm:ss')
  }

  public static gerarDataBr() {
    Moment.locale('pt')
    return Moment().format('DD/MM/YYYY')
  }

  public static validaCpfCnpj(cpfCnpjCodigo: string): any {
    const tamanho = cpfCnpjCodigo.length
    if (tamanho < 11 || tamanho > 14) {
      return {success: false, message: Mensagem.MSG_E004('CPF', 'o')}
    }

    if (tamanho === 11 && !Validacoes.IsCPFValido(cpfCnpjCodigo)) {
      return {success: false, message: Mensagem.MSG_E004('CPF', 'o')}
    }

    if (tamanho === 14 && !Validacoes.IsCNPJValido(cpfCnpjCodigo)) {
      return {success: false, message: Mensagem.MSG_E004('CNPJ', 'o')}
    }
    return {success: true}
  }

  public static formatarMoedaReal(valor: any, casaDecimal = 2) {
    return valor.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: casaDecimal,
    })
  }

  public static montaOptions = (data: any[]): any[] => {
    const arr: any[] = []
    if (data && data.length > 0) {
      arr.push(
        ...data.map((item: any) => ({
          value: item.value,
          text: item.text,
        }))
      )
    }

    return arr
  }

  public static montaOptionsLabel = (data: []) => {
    let arr: any = []
    if (!!data && data.length > 0) {
      arr = data?.map(function (itens: any) {
        return {
          value: itens.value,
          label: itens.text,
        }
      })
      return arr
    }
  }

  public static montaOptionsText = (data: []) => {
    let arr: any = []
    if (!!data && data.length > 0) {
      arr = data?.map(function (itens: any) {
        return {
          value: itens.text,
          text: itens.text,
        }
      })
      return arr
    }
  }

  // Mascara CPF 000.000.000-00
  public static mascaraCPF = (value: any) => {
    if(value != null || value != '')  {
      return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
    }
    
  }

  public static mascaraCNPJ = (value: any) => {
    return value
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/, '$1.$2')
      .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
      .replace(/\.(\d{3})(\d)/, '.$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
  }

  // Mascara CEP 00000-000
  public static mascaraCEP = (value: any) => {
    return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
  }

  // Mascara telefone
  //(00) 00000-0000 : 15
  //11997520124 : 11
  public static mascaraTelefoneCelular = (value: any) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d)(\d{4})$/, '$1-$2')
    //.replace(/(\d{5})(\d{4})(\d)/, "$1-$2");
  }

  //Mascara data 00/00/0000
  public static mascaraDate = (value: any) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1')
  }

  // Aceita apenas que letras sejam digitadas
  public static somenteLetras = (value: any) => {
    return value.replace(/[0-9!@#¨$%^&*)(+=._-]+/g, '')
  }

  // Aceita apenas números
  public static apenasNumeros = (value: any) => {
    return value.replace(/\D/g, '')
  }

  // 02.321.351/0001-35
  // 02321351000135
  public static removerMascara = (value: any) => {
    if (Utils.isNotEmpty(value)) {      
      return value
        .replace(/[^0-9,]*/g, '')
        .replace(/[\(\)\.\s-]+/g, '')
        .replace(/\D/g, '')
    }
    
    return null
  }

  public static isNotEmpty(value: any | string) {
    return typeof value !== undefined && value !== null && value !== ''
  }

  public static maskCurrency = (value: string) => {
    if (isNaN(parseFloat(value))) {
      return '' // Retorna string vazia se a entrada não for um número válido
    }
    let valueNew = value.replace('.', '').replace(',', '').replace(/[^\d]/g, '')
    const options = {minimumFractionDigits: 2}
    const result = new Intl.NumberFormat('pt-BR', options).format(parseFloat(valueNew) / 100)
    return result
  }

  public static maskCurrency1 = (value: string) => {
    let valueNew = value.replace('', '.')
    //.replace('', ',').replace(/\D/g, '')
    const options = {minimumFractionDigits: 2}
    const result = new Intl.NumberFormat('en-US', options).format(parseFloat(valueNew) / 100)
    return result
  }

  public static somenteNumeros(num: any) {
    var er = /[^0-9.]/
    er.lastIndex = 0
    var campo = num
    if (er.test(campo.value)) {
      campo.value = ''
    }
  }

  public static somenteNumerosVirg(num: any) {
    var er = /[^0-9,]+/g
    er.lastIndex = 0
    var campo = num
    if (er.test(campo.value)) {
      campo.value = ''
    }
  }

  public static toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  public static acceptExcel: any[] = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    //'application/vnd.ms-excel',
  ]

  public static excluirLinhaDoGrid = (event: any, nome: any, dados: any) => {
    event.preventDefault()
    const novoData: any = dados.filter((i: any) => i.filtro !== nome)
    return novoData
  }

  public static downloadArquivo = (planilhaBase64: string, nome: string) => {
    const link = document.createElement('a')
    link.href = `data:${this.acceptExcel};base64,${planilhaBase64}`
    link.download = nome
    link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}))
  }

  public static filtroMenuAcao = (dadosMenu: any, tipoMenu: string, tipoAcao: string) => {
    const permissoes = dadosMenu
      .filter((item: any) => item[tipoMenu])
      .map((item: any) => item[tipoMenu])
      .flat()
    return permissoes.includes(tipoAcao)
  }
}
