/* eslint-disable */
import React, {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'

const PrivateRoutes = () => {
  const CadastrosPage = lazy(() => import('../modules/cadastro/CadastrosPage'))
  const PremiadosPage = lazy(() => import('../modules/premiado/PremiadosPage'))
  const CampanhaPage = lazy(() => import('../modules/campanha/CampanhaPage'))
  const PedidosPage = lazy(() => import('../modules/pedido/PedidosPage'))
  const FinanceiroPage = lazy(() => import('../modules/financeiro/FinanceiroPage'))
  const LojaProdutoPage = lazy(() => import('../modules/lojaProduto/LojaProdutoPage'))
  const RelatorioPage = lazy(() => import('../modules/relatorio/RelatorioPage'))
  const PerfilPage = lazy(() => import('../modules/perfil'))
  const ComunicadosPage = lazy(() => import('../modules/comunicados/ComunicadosPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='/pages/cadastro/*'
          element={
            <SuspensedView>
              <CadastrosPage />
            </SuspensedView>
          }
        />
        <Route
          path='/pages/premiado/*'
          element={
            <SuspensedView>
              <PremiadosPage />
            </SuspensedView>
          }
        />
        <Route
          path='/pages/campanha/*'
          element={
            <SuspensedView>
              <CampanhaPage />
            </SuspensedView>
          }
        />
        <Route
          path='/pages/pedido/*'
          element={
            <SuspensedView>
              <PedidosPage />
            </SuspensedView>
          }
        />
        <Route
          path='/pages/financeiro/*'
          element={
            <SuspensedView>
              <FinanceiroPage />
            </SuspensedView>
          }
        />
        <Route
          path='/pages/lojaProduto/*'
          element={
            <SuspensedView>
              <LojaProdutoPage />
            </SuspensedView>
          }
        />
        <Route
          path='/pages/relatorio/*'
          element={
            <SuspensedView>
              <RelatorioPage />
            </SuspensedView>
          }
        />
        <Route
          path='/pages/comunicados/*'
          element={
            <SuspensedView>
              <ComunicadosPage />
            </SuspensedView>
          }
        />
        <Route
          path='/pages/meuPerfil'
          element={
            <SuspensedView>
              <PerfilPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
