export class Interceptors {
  public static responseInterceptorSuccess(response: any) {
    return {
      data: response.data,
      succeeded: true,
      message: '',
    };
  }

  static responseInterceptorError(error: any) {
    return Promise.reject(error.response);
  }
}
